import React from 'react';
import { actions as a } from './dictionary';
import { clearTokenFromStorage, storeToken } from './keep';


export const initialState = {
    token: null,
    status: null
};

export const reducer = (state, action) => {

    switch (action.type) {

        case a.LOGOUT:
            clearTokenFromStorage();
            return initialState;

        case a.TOKEN_RECEIVED:
            if (action.isFromAPI) storeToken(action.token);

            return {...state, token: action.token };

        case a.STATUS_UPDATE:
            return {...state, status: action.status }

        case a.SETTINGS_LOADED:
            return {...state,
                settings: action.settings };
        case a.SETTING_UPDATED:
            return {...state,
                settings: { ...state.settings, ...action.setting }};

        default:
            return state;
    }
};

export const GlobalContext = React.createContext();
