import { useEffect, useState } from 'react';
import useBackendAPI from '../../useBackendAPI';


export default function useForceChurnAPI({ company }) {

    const [ isWaiting, setIsWaiting ] = useState(true);
    const [ callAPI ] = useBackendAPI({ requiresAuth: true });

    useEffect(() => {

        if (! isWaiting)
            callAPI({
                method: 'DELETE',
                url: `/companies/${company.id}`
            });

    }, [ callAPI, isWaiting, company ]);

    return [ setIsWaiting ];
}
