import { useEffect, useContext } from 'react';
import { actions as a } from "../../dictionary";
import { GlobalContext } from '../../globalState';
import useComponentVisible from '../../useComponentVisible';


export default function useModalMount(initialVisible, routeOnClosure) {

    const { dispatch } = useContext(GlobalContext);
    const [ ref, isMounted, setIsMounted ] = useComponentVisible(initialVisible, null, routeOnClosure);

    useEffect(() => {

        if (isMounted === true) {
            dispatch({ type: a.LOCK_SCROLL });
        }

    }, [ isMounted, dispatch ]);

    return [ ref, isMounted, setIsMounted ];
}
