export default {
    // useBackendAPI actions
    RESOLVED: 'RESOLVED',
    CONFIG: 'CONFIG',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    REQUEST_START: 'REQUEST_START',
    REQUEST_END: 'REQUEST_END',
    TOKEN_RECEIVED: 'TOKEN_RECEIVED',

    // other actions
    SETTINGS_LOADED: 'SETTINGS_LOADED',
    SETTING_UPDATED: 'SETTING_UPDATED',
    SORTING_CHANGED: 'SORTING_CHANGED',
    MODE_CHANGED: 'MODE_CHANGED',
    NEW_BATCH: 'NEW_BATCH',
    ITEM_CLICKED: 'ITEM_CLICKED',
    CARD_CLOSED: 'CARD_CLOSED',
    LOCK_SCROLL: 'LOCK_SCROLL',
    STATUS_UPDATE: 'STATUS_UPDATE',
    LOGOUT: 'LOGOUT'
};
