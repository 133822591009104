const { words: w } = require('./');


/* Order of items defines order of tabs */
export default [
    w.subscribed,
    w.trials,
    w.coOp,
    w.failedTrials,
    w.preChurned,
    w.churned,
    w.overdue,
    w.suspended,
    w.active,
    w.all
]
