import { words as w } from './index';


export default {
    subscribedAt: 'subscribed at',
    planId: `${w.plan} id`,
    ownerName: `${w.owner} ${w.name}`,
    ownerNameFirst: `first ${w.name}`,
    ownerNameLast: `last ${w.name}`,
    ownerEmail: `${w.owner} ${w.email}`,
    ownerPhone: `${w.owner} ${w.phone}`,
    memberCount: `${w.member} ${w.count}`,
    memberLimit: `${w.member} ${w.limit}`,
    logCount: `${w.log} ${w.count}`,
    failedTrials: `failed ${w.trials}`,
    createdAt: 'created at',
    switchOwner: 'switchOwner',
    subscriptionPlan: `${w.subscription} ${w.plan}`,
    cancelSubscription: `${w.cancel} ${w.subscription}`,
    trialEndsAt: `${w.trial} ends at`,
    trialFailedAt: `${w.trial} failed at`,
    trialExtensionsRemaining: `${w.trial} extensions remaining`,
    canceledAt: `${w.canceled} at`,
    campaignCount: `${w.campaign} count`,
    braintreeCustomer: `Braintree customer`,
    requestEnded: `request ended`,
    loginAsOwner: `login as owner`,
    stateChangedAt: `state changed`,
    stateTag: `state tag`,
    partnerId: 'partner ID',
    switchToDealer: 'switch to dealer',
    forceChurn: 'force churn',
    googleDriveSync: 'GoogleDriveSync',
    manageAtBraintree: 'manage at braintree',
    toReEnableButtons: 'Close the card to re-enable the buttons'
}
