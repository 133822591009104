import React from 'react';
import { generatePath, NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { routes as r, modes } from '../../dictionary';
import useCompanyCountsGetAPI from './useCompanyCountsGetAPI';
import CompanyTable from './CompanyTable';


export default function CompanyRoot() {

    const counts = useCompanyCountsGetAPI();

    return (

        <div id="companies">
            <nav className="flat">

                { modes.map(mode =>
                    <NavLink key={ mode } to={ generatePath(r.companyList, { mode }) }>
                        { mode }
                        { counts &&
                        <small>{ counts[mode] }</small> }
                    </NavLink> )}

            </nav>

            <Switch>
                <Route path={ r.companyList } component={ CompanyTable } />

                <Redirect to={ generatePath(r.companyList, { mode: modes[0] }) } />
            </Switch>
        </div>
    );
}
