import { useEffect, useState } from 'react';
import useBackendAPI from '../../useBackendAPI';


export default function useCompanyUpdateAPI({ company }) {

    const [ data, setData ] = useState(null);
    const [ callAPI, state ] = useBackendAPI({ requiresAuth: true });

    useEffect(() => {

        if (data)
            callAPI({
                method: 'PATCH',
                url: `/companies/${company.id}`,
                data
            });

    }, [ callAPI, data, company ]);

    return [ setData, state.data ];
}
