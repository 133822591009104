import React, { useReducer, useEffect } from 'react';
import {withRouter, Redirect, Switch, NavLink, Route} from 'react-router-dom';
import { GlobalContext, initialState, reducer } from '../globalState';
import { storeSettings, getSettingsFromStorage, getTokenFromStorage } from '../keep';
import { routes as r, actions as a, words as w } from '../dictionary';
import CompanyRoot from './Company';
import '../styling/css/general.css';
import '../styling/css/icons.css';
import Login from './Login';


function Base() {

    const [ state, dispatch ] = useReducer(reducer, initialState);
    const { settings, status, token } = state;

    // get token from local storage
    useEffect(() => {

        if (token) return;

        dispatch({ type: a.TOKEN_RECEIVED, token: getTokenFromStorage() || false });

    }, [ dispatch, token ]);

    // LOAD/STORE SETTINGS
    useEffect(function moveSettings() {

        if (settings)
            storeSettings(settings);

        if (! settings)
            dispatch({ type: a.SETTINGS_LOADED, settings: getSettingsFromStorage() });

    }, [ settings ]);

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>

            { status === w.loading &&
            <div id="loader"><div /></div> }

            <div id="main">

                { ! token &&
                <Switch>
                    <Route path={ r.login } component={ Login } />
                    <Redirect to={ r.login } />
                </Switch> }

                { token && <>
                    <nav className="tabs">
                        <NavLink to={ r.companies }>
                            <i className="icon icon-office-2" />
                            { w.companies }
                        </NavLink>

                        <NavLink to={ r.users }>
                            <i className="icon icon-users-ww" />
                            { w.users }
                        </NavLink>

                        <button className="small" id="logout"
                                onClick={ () => dispatch({ type: a.LOGOUT }) }>
                            { w.logout }
                        </button>
                    </nav>

                    <div>
                        <div id="status" title="status bar"><code>{ status }</code></div>

                        <Switch>
                            <Route path={ r.companies } component={ CompanyRoot } />
                            <Redirect to={ r.companies } />
                        </Switch>

                    </div> </> }
            </div>
        </GlobalContext.Provider>
    );
}

export default withRouter(Base);
