import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';


export default function useComponentVisible(initialIsVisible, isDisabled, routeOnClosure) {
  
    const [ isComponentVisible, setIsComponentVisible ] = useState(initialIsVisible);
    const history = useHistory();
    const ref = useRef(null);

    useEffect(() => {

        const handleClickOutside = (event) => {

            if (ref.current && ! ref.current.contains(event.target)) {
                setIsComponentVisible(false);

                if (routeOnClosure) {
                    if (typeof routeOnClosure === 'function')
                        routeOnClosure()
                    else
                        history.push(routeOnClosure);
                }
            }
        };

        if (! isDisabled)
            document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [ isDisabled, history, routeOnClosure ]);

    return [ ref, isComponentVisible, setIsComponentVisible, isDisabled ];
}
