import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Base from './components/Base';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <Router>
        <Base />
    </Router>, document.getElementById('root'),
);

serviceWorker.unregister();
