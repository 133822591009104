import React, { useReducer, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTable, usePagination, useSortBy, useGlobalFilter, useAsyncDebounce } from 'react-table';
import useCompanyGetManyAPI from './useCompanyGetManyAPI';
import { actions as a } from '../../dictionary';
import reducer, { initialState } from './reducer';
import Modal from '../Modal/Modal.jsx';
import useModalMount from '../Modal/useModalMount';
import CompanyCard from './CompanyCard';
import useExportToCSV from '../../useExportToCSV';


const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'State Changed At', accessor: 'stateTagChangedAt', sortDescFirst: true },
    { Header: 'Created At', accessor: 'createdAt', sortDescFirst: true },
    { Header: 'State', accessor: 'stateTag' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Plan ID', accessor: 'payment.subscription.plan.id' },
    { Header: 'Partner ID', accessor: 'payment.partnerId' },
    { Header: 'First Name', accessor: 'owner.name.first' },
    { Header: 'Last Name', accessor: 'owner.name.last' },
    { Header: 'E-mail', accessor: 'owner.email' },
    { Header: 'Members', accessor: 'memberCount', sortDescFirst: true },
    { Header: 'Logs', accessor: 'logCount', sortDescFirst: true }
];

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {

    const count = preGlobalFilteredRows.length;
    const [ value, setValue ] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200);

    // set global filter if the component re-rendered
    useEffect(() => {

        if (value && ! globalFilter)
            setGlobalFilter(value);

    }, [ globalFilter, value, setGlobalFilter ]);

    return (<div id="global-search">
        <input placeholder={ `Search ${count} companies...`}
               value={value || ""}
               onChange={e => {
                   setValue(e.target.value);
                   onChange(e.target.value);
               }} />
    </div>)
}

function Table({ columns, data, dispatch, mode }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable({
        columns,
        data,
        initialState: { pageSize: 25 }
    }, useGlobalFilter, useSortBy, usePagination );

    const [ startExport ] = useExportToCSV({ rows, columns, globalFilter, mode });

    return (<>

            <GlobalFilter preGlobalFilteredRows={ preGlobalFilteredRows }
                          globalFilter={ globalFilter }
                          setGlobalFilter={ setGlobalFilter } />

            <table { ...getTableProps() }>
                <thead>
                { headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps() }>
                        <th>#</th>
                        { headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps()) }>
                                { column.render('Header') }
                                <span>{ column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : '' }</span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                { page.map((row, index) => {
                    prepareRow(row);
                    return <tr {...row.getRowProps()} onClick={ () => dispatch({ type: a.ITEM_CLICKED, item: row.original })} >
                        <td>{ index + 1 }</td>
                        { row.cells.map(cell =>
                        <td {...cell.getCellProps() }>{ cell.render('Cell') }</td>)}
                    </tr>
                })}
                </tbody>
            </table>

            <div className="pagination">
                <button onClick={ () => gotoPage(0) } disabled={ ! canPreviousPage }>
                    {'<<'}
                </button>{' '}
                <button onClick={ () => previousPage() } disabled={ ! canPreviousPage }>
                    {'<'}
                </button>{' '}
                <button onClick={ () => nextPage() } disabled={ ! canNextPage }>
                    {'>'}
                </button>{' '}
                <button onClick={ () => gotoPage(pageCount - 1) } disabled={ ! canNextPage }>
                    {'>>'}
                </button>{' '}

                <button title="Download CSV-file" onClick={ () => startExport() } >
                    <i className="icon icon-data-download" />
                </button>{' '}

                <span>Page{' '} <strong>{pageIndex + 1} of { pageOptions.length }</strong>{' '}</span>
                <span>| Go to page:{' '}
                    <input type="number" defaultValue={pageIndex + 1}
                           onChange={e => {
                               const page = e.target.value ? Number(e.target.value) - 1 : 0;
                               gotoPage(page);
                           }}
                           style={{ width: '100px' }} />
                    </span>{' '}

                <select value={ pageSize } onChange={e => { setPageSize(Number(e.target.value)) }}>
                    {[ 10, 25, 40, 50 ].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
}

export default function CompanyTable() {

    const { mode } = useParams();
    const batch = useCompanyGetManyAPI();
    const [ companyCardRef, isCompanyCardMounted, setCompanyCardMounted ] =
        useModalMount(false, () => dispatch({ type: a.CARD_CLOSED }));
    const [ state, dispatch ] = useReducer(reducer, initialState);
    const { tabCompanies, focusedItem } = state;

    useEffect(() => {

        dispatch({ type: a.MODE_CHANGED, mode });

    }, [ mode ]);

    useEffect(() => {

        if (batch)
            dispatch({ type: a.NEW_BATCH, batch })

    }, [ batch ]);

    useEffect(() => {

        setCompanyCardMounted(Boolean(focusedItem));

    }, [ focusedItem , setCompanyCardMounted ]);

    return (<>

        { isCompanyCardMounted &&
        <Modal _ref={ companyCardRef }>
            <CompanyCard company={ focusedItem } mode={ mode } close={ () => setCompanyCardMounted(false) } />
        </Modal> }

        <Table columns={ columns } mode={ mode } data={ tabCompanies } dispatch={ dispatch } />
    </>);
}
