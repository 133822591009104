import { useContext, useEffect, useReducer, useState } from 'react';
import { actions as a, words as w } from './dictionary';
import { GlobalContext } from './globalState';
import { baseURL } from './useBackendAPI';


const initialState = {
    hasStarted: false,
    isInProgress: false,
    hasFinished: false,
    hasFailed: null,
    hasSucceeded: null,
    config: null,
    stream: null,
    headers: [],
    errorMessage: null,
    status: null
};

function reducer(state, action) {

    switch (action.type) {

        case a.CONFIG:
            return {
                ...initialState,
                isInProgress: true,
                hasStarted: true,
                config: action.config };
        case a.SUCCESS:
            return {
                ...state,
                hasSucceeded: true,
                hasFailed: false,
                isInProgress: false,
                hasFinished: true,
                status: action.response.status,
                stream: action.response.body,
                headers: action.response.headers };
        case a.ERROR:
            return {
                ...state,
                hasSucceeded: false,
                hasFailed: true,
                isInProgress: false,
                hasFinished: true,
                ...action.response && {
                    status: action.response.status,
                    errorMessage: action.response.message,
                    headers: action.response.headers
                }};
        default:
            throw new Error('Unknown action type');
    }
}

export default function useBackendAPIstream() {

    const [ requestConfig, setRequestConfig ] = useState(null);
    let context = useContext(GlobalContext);
    const { state: { token }, dispatch: globalDispatch } = context;
    const [ state, dispatch ] = useReducer(reducer, initialState);

    // CONFIGURE REQUEST
    useEffect(() => {

        if (requestConfig && requestConfig.method && requestConfig.url) {

            if (! token)
                throw new Error('Token not provided');

            dispatch({
                type: a.CONFIG,
                config: requestConfig });
        }

    }, [ requestConfig, token ]);

    // MAKE THE CALL
    useEffect(() => {

        async function performRequest() {

            const config = {
                ...state.config,
                headers: {...state.config.headers, 'Authorization': `Bearer ${token}` }
            };

            globalDispatch({ type: a.STATUS_UPDATE, status: w.loading });

            try {

                const { url, ...resOfConfig } = config;
                const response = await fetch(`${baseURL}${config.url}`, resOfConfig);

                if (! response.ok) {
                    console.log('Error message:', response.status, response.body);
                    console.log('Original request ID:', response.headers['x-request-id']);
                    dispatch({ type: a.ERROR, response });
                    globalDispatch({ type: a.STATUS_UPDATE, status: w.error });
                    return;
                }

                dispatch({ type: a.SUCCESS, response });
                globalDispatch({ type: a.STATUS_UPDATE, status: w.ok });

            } catch (error) {

                console.error('Unexpected:', error.message, state.config);
                dispatch({ type: a.ERROR });

                if (globalDispatch)
                    globalDispatch({ type: a.STATUS_UPDATE, status: w.error });

            } finally {
                globalDispatch({ type: a.REQUEST_END });
            }
        }

        if (state.config && ! state.hasFinished)
            performRequest();

    }, [ state, globalDispatch, token ])

    return [ setRequestConfig, state ];
}
