import { useEffect, useState } from 'react';
import useBackendAPI from '../../useBackendAPI';


export default function useUserTokenGetAPI() {

    const [ options, setOptions ] = useState({});
    const [ callAPI, state ] = useBackendAPI({ requiresAuth: true });

    useEffect(() => {

        if (options.userId)
            callAPI({
                method: 'GET',
                ...options.env && { params: { env: options.env }},
                url: `/users/${options.userId}/token` });

    }, [ callAPI, options ]);

    return [ setOptions, state.data ? state.data.token : null ] ;
}
