import React from 'react';
import ReactDOM from 'react-dom';
import '../Company/company-card.css';


export default function Modal({ _ref, children, noPortal=false }) {

    const Element = (
        <div id="modal" >
            <div ref={ _ref } className="content">
                { children }
            </div>
        </div>
    );

    return noPortal ? Element : (ReactDOM.createPortal(Element, document.body));
};
