import React, { useEffect, useState } from 'react';


// TODO add reducer if further complexity added

export default function EditableDD({ callback, filterFunction=(v)=>v, ...props }) {

    const key = Object.keys(props)[0];
    const initialValue = props[key];
    const [ hover, setHover ] = useState(false);
    const [ editMode, setEditMode ] = useState(false);
    const [ inputValue, setInputValue ] = useState(initialValue || '');
    const [ filteredValue, setFilteredValue ] = useState(inputValue);

    useEffect(() => {

        setFilteredValue(filterFunction(inputValue));

    }, [ inputValue, filterFunction ]);

    return (<dd onMouseEnter={ () => setHover(true) }
                onMouseLeave={ () => setHover(false) } >

        { ! editMode && initialValue }

        { (! editMode && hover) &&
        <button onClick={ () => setEditMode(true) }>
            <i className="icon icon-pen" />
        </button> }

        { editMode &&
        <button onClick={ () => { callback({ [key]: filteredValue });
                                  setEditMode(false); }}>
            <i className="icon icon-f-check" />
        </button> }

        { editMode &&
        <input value={ inputValue }
               onChange={ (e) => setInputValue(e.target.value)} /> }

        { (editMode && (filteredValue !== inputValue)) &&
        <small className="newline">{ filteredValue }</small> }
    </dd>);
}
