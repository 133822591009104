import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    dateFormat,
    messages as m,
    phrases,
    phrases as p,
    words as w,
} from '../../dictionary';
import SafeButton from '../SafeButton';
import useUserTokenGetAPI from '../User/useUserTokenGetAPI';
import useCompanyCommandAPI from './useCompanyCommandAPI';
import useForceChurnAPI from './useForceChurnAPI';
import EditableDD from './EditableDD';
import useCompanyUpdateAPI from './useCompanyUpdateAPI';
import { GeocodingOptions } from '../GeocodingOptions';


const MAIN_WEBAPP_URL = process.env.REACT_APP_MAIN_WEBAPP_URL || 'https://app.simplecrew.com';
const STAGING_WEBAPP_URL = 'https://webapp-stage.sc-wip.dev/';

export default function CompanyCard({ company: existingCompany, mode }) {

    const [ getUserToken, userToken ] = useUserTokenGetAPI();
    const [ loginAsOwnerClicked, setLoginAsOwnerClicked ] = useState(false);
    const [ performCommand, updatedCompanyAfterComand ] = useCompanyCommandAPI({ company: existingCompany });
    const [ updateFields, updatedCompanyAfterUpdate ] = useCompanyUpdateAPI({ company: existingCompany });
    const company = updatedCompanyAfterComand || updatedCompanyAfterUpdate || existingCompany;
    company.owner = company.owner ? company.owner : existingCompany.owner;
    const [ forceChurn ] = useForceChurnAPI({ company });
    const planId = company.payment.subscription.plan ? company.payment.subscription.plan.id : '';
    const trialEndMoment = moment(company.trialEndsAt);
    const creationMoment = moment(company.created);

    useEffect(() => {

        if (loginAsOwnerClicked)
            getUserToken({
                userId: company.owner.id,
                ...loginAsOwnerClicked === w.staging && { env: w.staging }
            });

    }, [ loginAsOwnerClicked, company, getUserToken ]);

    // when we have user token ready - form the link and open it in a new tab
    useEffect(() => {

        if (userToken && loginAsOwnerClicked) {
            const url = new URL(`../${company.id}/campaigns`, loginAsOwnerClicked === w.staging ? STAGING_WEBAPP_URL : MAIN_WEBAPP_URL);
            url.search = `?token=${userToken}`;
            window.open(url.toString());
            setLoginAsOwnerClicked(false);
        }
    }, [ userToken, company.id, loginAsOwnerClicked ]);

    return (<>
        <h1>Company card</h1>

        <dl>
            <dt>ID</dt>
            <dd>{ company.id }</dd>

            <dt>name</dt>
            <dd>{ company.name }</dd>

            <dt>{ p.ownerEmail }</dt>
            <EditableDD callback={ data => performCommand([ p.switchOwner, data ]) }
                        newOwnerEmail={ company._owner?.email || company.owner.email } />

            <dt>{ p.ownerPhone }</dt>
            <dd>{ company.owner.phone ? company.owner.phone.number : '' }</dd>

            <dt>{ p.ownerName }</dt>
            <dd>{ company.owner.name.first } { company.owner.name.last }</dd>

            <dt>{ p.createdAt }</dt>
            <dd>{ company.created }</dd>

            <dt>{ w.Counts }</dt>
            <dd>{ company.memberCount || company._people.length } members / { company.logCount } logs</dd>

            <dt>Geocoder</dt>
            <dd><GeocodingOptions company={ company } /></dd>
        </dl>

        <dl>
            <dt>{ w.state }</dt>
            <dd title={ company.stateTagChangedAt }>
                { company.stateTag }&nbsp;
                <small>(since { company.stateTagChangedAt })</small>
            </dd>

            <dt>{ w.suspended }</dt>
            <dd title={ company.suspended }>
                { company.suspended ? moment(company.suspended).format(dateFormat.short) : 'no' }

                { (! company.suspended && mode === w.active) &&
                <SafeButton message={ m.confirmSuspend } onConfirm={ () => performCommand([ w.suspend ]) }>
                    { w.suspend }
                </SafeButton> }

                { (company.suspended && mode === w.suspended) &&
                <SafeButton message={ m.confirmUnSuspend } onConfirm={ () => performCommand([ w.unSuspend ]) }>
                    { w.unSuspend }
                </SafeButton> }
            </dd>

            <dt>{ p.subscriptionPlan }&nbsp;/&nbsp;id</dt>
            <dd>
                { planId }
                { company.payment.subscription.id &&
                <small> /&nbsp;
                    <a href={ `${process.env.REACT_APP_BRAINTREE_URL || 'https://www.braintreegateway.com/merchants/cqzd7hd8x9vvqy5k'}/subscriptions/${company.payment.subscription.id}` }
                       title="Manage at Braintree"
                       rel="noopener noreferrer"
                       target="_blank">{ company.payment.subscription.id }</a>
                </small>
                }
            </dd>

            <dt>{ p.braintreeCustomer }</dt>
            <dd>{ company.payment.customer.id &&
            <a href={ `${process.env.REACT_APP_BRAINTREE_URL || 'https://www.braintreegateway.com/merchants/cqzd7hd8x9vvqy5k'}/customers/${company.payment.customer.id}` }
               title="Manage at Braintree"
               rel="noopener noreferrer"
               target="_blank">{ company.payment.customer.id }</a> }
            </dd>

            <dt>{ p.partnerId }</dt>
            <dd>{ company.payment.partnerId }</dd>

            <dt>set trial period</dt>
            { company.trialEndedAt &&
            <dd>N/A</dd> }
            { ! company.trialEndedAt &&
            <EditableDD callback={ updateFields }
                        trialEndsAt={ moment.duration(trialEndMoment.diff(creationMoment)).humanize() }
                        filterFunction={ value => moment(company.created)
                            .add(moment.duration(...value.split(' ')))
                            .format(dateFormat.dayISO) } /> }

            <dt>{ p.memberLimit }</dt>
            <EditableDD callback={ updateFields } peopleLimit={ company.peopleLimit }
                        filterFunction={ (newValue) => Number(newValue) < company.memberCount
                            ? company.memberCount
                            : newValue  } />

            <dt>{ p.trialExtensionsRemaining }</dt>
            <dd>{ company.trialExtensionsRemaining }</dd>
        </dl>

        <div className="actions">

            { (! company.payment.partnerId) &&
            <button onClick={ () => { performCommand([ 'switchToDealer', {
                planId: window.prompt('set dealer plan ID', 'hughes') } ]) } } >
                { p.switchToDealer }
            </button> }

            { [ w.preChurned, w.active ].includes(mode) &&
            <SafeButton message={ m.confirmForceChurn } onConfirm={ () => forceChurn() }>
                { p.forceChurn }
            </SafeButton>}

            <button title={ userToken ? phrases.toReEnableButtons : '' }
                    disabled={ userToken } onClick={ () => setLoginAsOwnerClicked(w.staging) } >{ p.loginAsOwner } (staging)</button>
            <button title={ userToken ? phrases.toReEnableButtons : '' }
                    disabled={ userToken } onClick={ () => setLoginAsOwnerClicked(true) } >{ p.loginAsOwner }</button>
        </div>
    </>);
}
