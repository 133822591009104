export default {
    token: 'token',
    subscribe: 'subscribe',
    subscribed: 'subscribed',
    subscription: 'subscription',
    payment: 'payment',
    plan: 'plan',
    successfully: 'successfully',
    canceled: 'pre-churned',
    cancel: 'cancel',
    updated: 'updated',
    actions: 'actions',
    share: 'share',
    export: 'export',
    photos: 'photos',
    photo: 'photo',
    delete: 'delete',
    active: 'active',
    stats: 'stats',
    billing: 'billing',
    company: 'company',
    companies: 'companies',
    change: 'change',
    address: 'address',
    settings: 'settings',
    loading: 'loading...',
    name: 'name',
    owner: 'owner',
    email: 'email',
    phone: 'phone',
    member: 'member',
    count: 'count',
    log: 'log',
    users: 'users',
    trials: 'trials',
    trial: 'trial',
    churned: 'churned',
    state: 'state',
    modify: 'modify',
    suspend: 'suspend',
    suspended: 'suspended',
    campaign: 'campaigns',
    ok: 'OK',
    error: 'ERROR',
    logout: 'logout',
    login: 'login',
    stateChangedAt: 'stateChangedAt',
    overdue: 'overdue',
    coOp: 'co-op',
    dealers: 'dealers',
    failedTrials: 'failed trials',
    preChurned: 'pre-churned',
    unSuspend: 'unsuspend',
    locations: 'locations',
    addOns: 'add-ons',
    all: 'all',
    limit: 'limit',
    staging: 'staging',
    Counts: 'Counts',
    Google: 'Google',
    Nominatim: 'Nominatim'
}
