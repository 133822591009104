import { words as w } from './dictionary';


export function getTokenFromStorage() {
    return localStorage.getItem(w.token);
}

export function clearTokenFromStorage() {
    localStorage.removeItem(w.token);
}

export function storeToken(accessToken) {
    localStorage.setItem(w.token, accessToken);
}

export function hasTokenInStorage() {
    const token = getTokenFromStorage();
    return Boolean(token);
}

export function storeSettings(settings) {
    const currentSettings = JSON.parse(localStorage.getItem(w.settings)) || {};
    localStorage.setItem(w.settings, JSON.stringify({ ...currentSettings, ...settings }));
}

export function getSettingsFromStorage() {
    return JSON.parse(localStorage.getItem(w.settings) || '{}');
}
