import { useEffect, useState } from 'react';
import useBackendAPIstream from '../../useBackendAPIstream';


export default function useCompanyGetManyAPI() {

    const [ callAPI, state ] = useBackendAPIstream();
    const [ companies, setCompanies ] = useState([]);

    useEffect(() => {

        callAPI({ url: '/companies', method: 'GET' });

    }, [ callAPI ]);

    useEffect(() => {

        if (state.hasFailed || ! state.stream) return;

        const reader = state.stream.getReader();
        let textBuffer = [];

        async function doRead() {

            const { value, done } = await reader.read();

            if (done) {
                // send leftover line
                setCompanies([ JSON.parse(textBuffer.join('')) ]);
                return;
            }

            const textChunk = new TextDecoder('utf-8').decode(value);
            textBuffer = [...textBuffer, ...textChunk ];
            const parsedItems = [];

            while (textBuffer.indexOf('\n') > 0) { // while separator is somewhere in the chunk
                const position = textBuffer.indexOf('\n');

                // extract first line
                const jsonStringArray = textBuffer.splice(0, position);

                // remove leftover \n separator
                textBuffer.shift();

                // parse and send object upstream
                parsedItems.push(JSON.parse(jsonStringArray.join('')));
            }

            setCompanies(parsedItems);

            doRead();
        }

        doRead();

    }, [ state ]);

    return companies;
}
