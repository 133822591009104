import React from 'react';
import { words as w } from '../dictionary';
import useCompanyUpdateAPI from './Company/useCompanyUpdateAPI';


const options = {
    [w.Google]: 'googleReverseGeocode',
    [w.Nominatim]: 'nominatimReverseGeocode' };

export function GeocodingOptions({ company }) {

    const [ updateFields, updatedCompany ] = useCompanyUpdateAPI({ company });

    return (
        <select name="geocoding-options"
                value={ updatedCompany?.geocodeFunction || company.geocodeFunction || options[w.Nominatim] }
                onChange={ (e) => updateFields({ geocodeFunction: e.target.value }) }>
            { Object.entries(options).map( ([ key, value ]) =>
            <option key={ value } value={ value }>{ key }</option> ) }
        </select>);
}
