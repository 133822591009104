import moment from 'moment';
import { actions as a, words as w } from '../../dictionary';
import modeQueries from './modeQueries';


export const initialState = {
    tabCompanies: [],
    allCompanies: [],
    focusedItem: null,
    mode: w.subscribed
}

function formatDates(item) {
    item.createdAt = moment(item.created).format('YY-MM-DD');
    item.stateTagChangedAt = moment(item.stateTagChangedAt).format('YY-MM-DD');
    return item;
}

export default function reducer(state, action) {

    switch (action.type) {

        case a.MODE_CHANGED:
            return {...initialState,
                mode: action.mode,
                allCompanies: state.allCompanies,
                tabCompanies: state.allCompanies.filter(modeQueries[action.mode]) };

        case a.ITEM_CLICKED:
            return {...state,
                focusedItem: action.item }

        case a.CARD_CLOSED:
            return {...state,
                focusedItem: null }

        case a.NEW_BATCH:
            return {...state,
                allCompanies: [...state.allCompanies, ...action.batch.map(formatDates) ],
                tabCompanies: [...state.tabCompanies, ...action.batch.filter(modeQueries[state.mode])]
            }

        default:
            throw new Error('Unknown action type');
    }
}
