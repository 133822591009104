import { useEffect } from 'react';
import useBackendAPI from '../../useBackendAPI';


export default function useCompanyCountsGetAPI() {

    const [ callAPI, state ] = useBackendAPI({ requiresAuth: true });

    // PERFORM CALL
    useEffect(() => {

            callAPI({ method: 'GET', url: `/companies/counts` })

    }, [ callAPI ]);

    return state.data;
}
