import { useEffect, useState } from 'react';
import useBackendAPI from '../../useBackendAPI';


export default function useCompanyCommandAPI({ company }) {

    const [ command, setCommand ] = useState([ null, {} ])
    const [ callAPI, state ] = useBackendAPI({ requiresAuth: true });


    useEffect(() => {

        const [ commandName, data={} ] = command;

        if (commandName) {
            console.debug('sending command:', commandName, data);
            callAPI({
                method: 'POST',
                url: `/companies/${company.id}/commands/${commandName}`,
                data
            });
        }

    }, [ callAPI, company, command ]);

    useEffect(() => {

        if (state.hasFinished)
            setCommand([ null ]);

    }, [ state ]);

    return [ setCommand, state.data ? {...state.data, owner: state.data._owner } : null ];
}
