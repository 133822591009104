import React, { useState, useEffect, useContext } from 'react';
import { actions, words as w } from '../dictionary';
import useBackendAPI from '../useBackendAPI';
import { GlobalContext } from '../globalState';


export default function Login() {

    const { dispatch } = useContext(GlobalContext);
    const [ key, setKey ] = useState('');
    const [ formReady, setFormReady ] = useState(false);
    const [ callAPI, state ] = useBackendAPI({ requiresAuth: false });

    useEffect(() => {

        if (formReady)
            callAPI({ method: 'POST', url: '/login', data: { key }});

        setFormReady(false);

    }, [ formReady, key, callAPI ]);

    useEffect(() => {

        if (state.hasSucceeded)
            dispatch({ type: actions.TOKEN_RECEIVED, token: state.data.token, isFromAPI: true });

    }, [ state, dispatch ]);

    return (
        <form id="login" action="" onSubmit={ (e) => { e.preventDefault(); setFormReady(true) }}>

            <fieldset>
                <label htmlFor="key">Authentication Key</label>
                <input type="text" id="key" value={ key }
                       onChange={ (e) => setKey(e.target.value.trim()) } />
            </fieldset>

            <button className="primary">{ w.login }</button>
        </form>
    );
}
