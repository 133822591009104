import React from 'react';


export default function SafeButton({ children, onConfirm, message }) {

    return (
        <button onClick={ () => {
            if (window.confirm(`${message}?`))
                onConfirm();
        } }>
            { children }
        </button>
    );
}
