import axios from 'axios';
import { useEffect, useReducer, useState, useContext } from 'react';
import { actions as a, words as w } from './dictionary';
import { GlobalContext } from './globalState';


export const baseURL = process.env.REACT_APP_BASE_API_URL || 'https://admin-api.simplecrew.com/v0';
export const APIclient = axios.create({ baseURL });
APIclient.defaults.headers.post['Content-Type'] = 'application/json';

const initialState = {
    hasStarted: false,
    isInProgress: false,
    hasFinished: false,
    hasFailed: null,
    hasSucceeded: null,
    config: null,
    data: null,
    headers: [],
    errorMessage: null,
    statusCode: null
};

function reducer(state, action) {

    switch (action.type) {
        case a.RESOLVED:
            return initialState;
        case a.CONFIG:
            return {
                ...initialState,
                isInProgress: true,
                hasStarted: true,
                token: action.token,
                config: action.config };
        case a.SUCCESS:
            return {
                ...state,
                hasSucceeded: true,
                hasFailed: false,
                isInProgress: false,
                hasFinished: true,
                statusCode: action.response.status,
                data: action.response.data,
                headers: action.response.headers };
        case a.ERROR:
            return {
                ...state,
                hasSucceeded: false,
                hasFailed: true,
                isInProgress: false,
                hasFinished: true,
                ...action.response && {
                    statusCode: action.response.status,
                    errorMessage: action.response.data.message,
                    headers: action.response.headers
                }};
        default:
            throw new Error('Unknown action type');
    }
}

export default function useBackendAPI({ successMessage, requiresAuth=true }) {

    let context = useContext(GlobalContext);

    const { state: { token: tokenFromState }, dispatch: globalDispatch } = context;
    const [ requestConfig, setRequestConfig ] = useState(null);
    const [ state, dispatch ] = useReducer(reducer, { // TODO why not use the constant?
        isInProgress: false,
        hasFinished: false,
        hasFailed: null,
        hasSucceeded: null,
        errorMessage: null,
        config: null,
        data: null,
        token: null,
        headers: {}
    });

    // CONFIGURE REQUEST
    useEffect(() => {

        if (requestConfig && requestConfig.method && requestConfig.url) {

            const { token: tokenFromConfig, ...restOfConfig } = requestConfig;
            const token = tokenFromConfig || tokenFromState;

            if (requiresAuth && ! token)
                throw new Error('Token not provided');

            dispatch({
                type: a.CONFIG,
                config: restOfConfig,
                ...requiresAuth && { token }});
        }

    }, [ requestConfig, requiresAuth, tokenFromState ]);

    // CALL API
    useEffect(() => {

        async function performRequest() {

            const config = {
                ...state.config,
                ...state.token && { headers: {...state.config.headers, 'Authorization': `Bearer ${state.token}` }}
            };
            globalDispatch({ type: a.STATUS_UPDATE, status: w.loading });

            try {
                const response = await APIclient.request(config);
                dispatch({ type: a.SUCCESS, response });

                if (globalDispatch)
                    globalDispatch({ type: a.STATUS_UPDATE, status: w.ok });

            } catch (error) {

                // HANDLE ERROR RESPONSE
                if (error.response) {
                    console.log('Error message:', error.response.status, error.response.data.message);
                    console.log('Original request ID:', error.response.headers['x-request-id']);
                    error.message = error.response.data.message;
                    dispatch({ type: a.ERROR, response: error.response });
                } else {
                    console.error('Unexpected:', error.message, state.config);
                    dispatch({ type: a.ERROR });
                    globalDispatch({ type: a.STATUS_UPDATE, status: w.error });
                }

                if (globalDispatch)
                    globalDispatch({ type: a.STATUS_UPDATE, status: w.error });

            } finally {
                globalDispatch({ type: a.REQUEST_END });
            }
        }

        if (state.config && ! state.hasFinished)
            performRequest();

    }, [ state, successMessage, globalDispatch, requiresAuth ]);

    return [ setRequestConfig, state ];
}
