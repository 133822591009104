import Papa from 'papaparse';
import saveAs from 'jszip/vendor/FileSaver';
import { useEffect, useState } from 'react';


export default function useExportToCSV({ rows, columns, mode, globalFilter }) {

    const [ isWaiting, setIsWaiting ] = useState(true);

    useEffect(() => {

        if (isWaiting) return;

        const CSVrows = [ columns.map(col => col.Header), ...rows.map(row => Object.values(row.values))];
        const csv = Papa.unparse(CSVrows);
        saveAs(new Blob([ csv ], { type: "text/csv;charset=utf-8" }), // blob
               `admin_export_companies_${mode}${globalFilter ? '_filter_'+globalFilter : ''}.csv`); // filename

        setIsWaiting(true);

    }, [ isWaiting, rows, columns, mode, globalFilter ]);

    return [ setIsWaiting ];
}
